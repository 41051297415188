"use client";
import type React from "react";
import { GlobalProps } from "@lib/global";
import { HomeApiSchema } from "@lib/types/api/HomeApiSchema";
import { componentParser } from "@lib/componentParser";
import { ParallaxProvider } from "react-scroll-parallax";

export const Homepage: React.FC<any> = ({
  page,
  globals,
}: {
  globals: GlobalProps;
  page: HomeApiSchema;
}) => {
  const components = componentParser(globals, page.data.attributes.content);

  return <ParallaxProvider>{components}</ParallaxProvider>;
};
